import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

import Tick from 'views/shared/icons/Tick'
import Button from 'views/shared/Button'
import { backupCodesSelector } from 'state/concepts/login/selectors'
import CodesList from 'views/employee/EmployeeAccountSettings/Security/TwoFactorSettings/BackupCodesModal/CodesList'

const BackupCodesStep = ({ onOpenWorkspace }) => {
  const backupCodes = useSelector(backupCodesSelector)

  const {
    values: { methodChecker },
  } = useFormikContext()

  return (
    <>
      <p className="font-700 text-headline mb-12 company-name-break">
        <FormattedMessage id={`employeeTwoFactorSettings.setupWith.${methodChecker}`} />
      </p>
      <div className="d-flex flex-column align-items-center mb-24">
        <div className="wh-24-24 d-flex align-items-center justify-content-center">
          <Tick className="in-green-600" size={20} />
        </div>
        <p className="mb-0 text-body font-700 in-green-700 mt-16">
          <FormattedMessage id="employeeTwoFactorSettings.authenticationEnabled" />
        </p>
      </div>
      <CodesList backupCodes={backupCodes} isSetup />
      <Button
        className="mt-24"
        text={{ id: 'employeeTwoFactorSettings.openMyWorkspace' }}
        onClick={onOpenWorkspace}
        isFullWidth
      />
    </>
  )
}

BackupCodesStep.propTypes = { onOpenWorkspace: PropTypes.func.isRequired }

export default BackupCodesStep
