import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { pick } from 'ramda'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import WorkspacePreview from 'views/shared/WorkspacePreview'
import { ONBOARDING_STEPS_NAMES } from 'lib/constants/employees'
import handleLoginRedirect from 'utils/session/handleLoginRedirect'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { TWO_FACTOR_SETTING_METHODS, SETUP_TWO_FACTOR_SETTING_STEPS } from 'lib/constants/twoFactorSettings'
import { passwordSelector } from 'state/concepts/login/selectors'
import {
  currentUserProfileSelector,
  currentWorkspaceCodeSelector,
  currentWorkspaceTwoFactorSettingsSelector,
} from 'state/concepts/session/selectors'
import Button from 'views/shared/Button'
import steps from './steps'

const SetupTwoFactorSettings = ({ onSetupComplete }) => {
  const workspaceCode = useSelector(currentWorkspaceCodeSelector)
  const workspaceTwoFactorSettings = useSelector(currentWorkspaceTwoFactorSettingsSelector)
  const password = useSelector(passwordSelector)
  const currentUserProfile = useSelector(currentUserProfileSelector)

  const workspaceMethods = pick(['email', 'sms', 'app'], workspaceTwoFactorSettings)

  const [currentStep, setStep] = useState(SETUP_TWO_FACTOR_SETTING_STEPS.selectMethod)
  const handleNextStep = useCallback(() => setStep(currentStep + 1), [currentStep, setStep])

  const stepData = steps[currentStep]
  const { content: StepComponent, onSubmit, validationSchema } = stepData

  const onOpenWorkspace = () => {
    if (onSetupComplete) {
      onSetupComplete()
    } else {
      handleLoginRedirect(ONBOARDING_STEPS_NAMES.completed === currentUserProfile.onboardingStatus, workspaceCode)
    }
  }

  const setBackupCodesStep = useCallback(() => setStep(SETUP_TWO_FACTOR_SETTING_STEPS.backupCodes), [setStep])

  const handleSubmit = useFormSubmit(onSubmit, { onSuccess: handleNextStep, setBackupCodesStep })

  return (
    <div className="login__content mb-40">
      <WorkspacePreview className="mb-32" />
      <Formik
        initialValues={{
          password,
          methodChecker: TWO_FACTOR_SETTING_METHODS.email,
          otpCode: '',
          phoneNumber: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <StepComponent
          setStep={setStep}
          currentStep={currentStep}
          workspaceMethods={workspaceMethods}
          onOpenWorkspace={onOpenWorkspace}
        />
      </Formik>
      {!workspaceTwoFactorSettings.required && currentStep !== SETUP_TWO_FACTOR_SETTING_STEPS.backupCodes && (
        <div className="text-center mt-16">
          <Button
            onClick={onOpenWorkspace}
            className="main-btn--text-blue main-btn--text-sm pt-8 pb-8 pl-8 pr-8"
            kind="text"
            text={{ id: 'employeeTwoFactorSettings.remindMeLater' }}
          />
        </div>
      )}
    </div>
  )
}

SetupTwoFactorSettings.defaultProps = {
  onSetupComplete: null,
}

SetupTwoFactorSettings.propTypes = {
  onSetupComplete: PropTypes.func,
}

export default SetupTwoFactorSettings
