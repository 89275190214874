import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import Image from 'next/legacy/image'

import initials from 'utils/initials'
import isExpired from 'utils/session/isExpired'

const WorkspaceLogo = ({ isCurrent, workspace: { name, tokens }, className, dataCy, dataUrl }) => (
  <div
    className={classNames('main-workspace__image', className, {
      'main-workspace__image--blured': !isCurrent && isExpired(tokens),
      'main-userpic--initials': !dataUrl,
    })}
    data-cy={dataCy}
  >
    {dataUrl ? (
      <Image src={dataUrl} alt="Workspace logo" layout="fill" />
    ) : (
      <div className="main-userpic__initials" data-cy="user-initials">
        {initials(name || 'No name')}
      </div>
    )}
  </div>
)

WorkspaceLogo.defaultProps = {
  isCurrent: false,
  className: '',
  dataCy: undefined,
  dataUrl: undefined,
}

WorkspaceLogo.propTypes = {
  workspace: PropTypes.shape({
    name: PropTypes.string,
    tokens: PropTypes.shape(),
  }).isRequired,
  isCurrent: PropTypes.bool,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  dataUrl: PropTypes.string,
}

export default WorkspaceLogo
