import { useFormikContext } from 'formik'
import { FormattedMessage } from 'react-intl'

import AppFields from 'views/employee/EmployeeAccountSettings/Security/TwoFactorSettingsModal/AppStep/AppFields'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const AppStep = () => {
  const {
    values: { otpCode },
  } = useFormikContext()

  return (
    <>
      <p className="font-700 text-headline mb-12 company-name-break">
        <FormattedMessage id="employeeTwoFactorSettings.setupWith.app" />
      </p>
      <AppFields />
      <SubmitFormButton
        className="main-modal__footer-action mt-16"
        text={{ id: 'employeeTwoFactorSettings.enableVerification' }}
        disabled={!otpCode}
        isFullWidth
      />
    </>
  )
}

export default AppStep
