import PropTypes from 'prop-types'

const CleanLayout = ({ children }) => (
  <div className="app-wrap__viewport--gray overflow-y-scroll">
    <div className="auth container min-h-screen">{children}</div>
  </div>
)

CleanLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CleanLayout
