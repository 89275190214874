import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { SETUP_TWO_FACTOR_SETTING_STEPS } from 'lib/constants/twoFactorSettings'
import SmsMethodFields from 'views/employee/EmployeeAccountSettings/Security/TwoFactorSettingsModal/SmsMethodStep/SmsMethodFields'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const SmsMethodStep = ({ currentStep, setStep }) => {
  const isStepWithOtp = currentStep === SETUP_TWO_FACTOR_SETTING_STEPS.enableSms
  const {
    values: { phoneNumber, otpCode },
  } = useFormikContext()

  return (
    <>
      <p className="font-700 text-headline mb-12 company-name-break">
        <FormattedMessage id="employeeTwoFactorSettings.setupWith.sms" />
      </p>
      <SmsMethodFields
        currentStep={currentStep}
        setStep={setStep}
        verifyNumberStep={SETUP_TWO_FACTOR_SETTING_STEPS.verifyNumber}
        isStepWithOtp={currentStep === SETUP_TWO_FACTOR_SETTING_STEPS.enableSms}
      />
      <SubmitFormButton
        className="main-modal__footer-action mt-16"
        text={{
          id: isStepWithOtp
            ? 'employeeTwoFactorSettings.enableVerification'
            : 'employeeTwoFactorSettings.setSms.verifyPhoneNumber',
        }}
        disabled={isStepWithOtp ? !otpCode : !phoneNumber}
        isFullWidth
      />
    </>
  )
}

SmsMethodStep.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
}

export default SmsMethodStep
