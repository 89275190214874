import PropTypes from 'prop-types'
import { filter, keys } from 'ramda'
import { FormattedMessage } from 'react-intl'

import { TWO_FACTOR_SETTINGS } from 'lib/constants/helpCenterLinks'
import MethodsList from 'views/employee/EmployeeAccountSettings/Security/TwoFactorSettingsModal/SelectMethodStep/MethodsList'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const SelectMethodStep = ({ setStep, workspaceMethods }) => {
  const allowedMethods = keys(filter(Boolean, workspaceMethods))

  return (
    <>
      <p className="font-700 text-headline mb-12 company-name-break" data-cy="select-method-step">
        <FormattedMessage id="employeeTwoFactorSettings.protectAccount" />
      </p>
      <p className="text-body mb-24">
        <FormattedMessage id="employeeTwoFactorSettings.learnMore.description" />
        <a className="main-link text-underline ml-4" href={TWO_FACTOR_SETTINGS} target="_blank">
          <FormattedMessage id="employeeTwoFactorSettings.learnMore" />
        </a>
      </p>

      <MethodsList setStep={setStep} workspaceMethods={workspaceMethods} isSetup />

      {allowedMethods.length === 1 && (
        <SubmitFormButton
          className="main-modal__footer-action mt-16"
          text={{ id: 'employeeTwoFactorSettings.enableVerification' }}
          isFullWidth
          isDisabled={false}
          isLoading={false}
        />
      )}
    </>
  )
}

SelectMethodStep.defaultProps = {
  twoFactorSettings: null,
}

SelectMethodStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  twoFactorSettings: PropTypes.shape({
    methodChecker: PropTypes.string,
  }),
  workspaceMethods: PropTypes.shape({
    email: PropTypes.bool,
    sms: PropTypes.bool,
    app: PropTypes.bool,
  }).isRequired,
}

export default SelectMethodStep
