import { FormattedMessage } from 'react-intl'

import { otpCodeSchema, verifyPhoneNumberSchema } from 'lib/yupLocalised/schemas/userProfileTwoFactorSettings'
import { enableTwoFactorSettings, getOtpCodeBySms } from 'state/concepts/employee/actions'
import BackupCodesStep from 'views/employee/SetupTwoFactorSettings/BackupCodesStep'
import AppStep from './AppStep'
import SelectMethodStep from './SelectMethodStep'
import SmsMethodStep from './SmsMethodStep'

const steps = [
  {
    title: <FormattedMessage id="employeeTwoFactorSettings.selectMethod.title" />,
    content: SelectMethodStep,
    onSubmit: enableTwoFactorSettings,
  },
  {
    title: <FormattedMessage id="employeeTwoFactorSettings.setSms.title" />,
    content: SmsMethodStep,
    onSubmit: getOtpCodeBySms,
    validationSchema: verifyPhoneNumberSchema,
  },
  {
    title: <FormattedMessage id="employeeTwoFactorSettings.setSms.title" />,
    content: SmsMethodStep,
    onSubmit: enableTwoFactorSettings,
    validationSchema: otpCodeSchema,
  },
  {
    title: <FormattedMessage id="employeeTwoFactorSettings.setApp.title" />,
    content: AppStep,
    onSubmit: enableTwoFactorSettings,
    validationSchema: otpCodeSchema,
  },
  {
    title: <FormattedMessage id="employeeTwoFactorSettings.selectMethod.title" />,
    content: BackupCodesStep,
  },
]

export default steps
