import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React from 'react'

import { rootHttpClient } from 'lib/httpClient'
import WorkspaceLogoComponent from './component'

class WorkspaceLogo extends React.Component {
  static propTypes = {
    workspace: PropTypes.shape({
      logoUrl: PropTypes.string,
      uniqCode: PropTypes.string.isRequired,
    }).isRequired,
    isCurrent: PropTypes.bool,
  }

  static defaultProps = {
    isCurrent: false,
  }

  state = {
    dataUrl: undefined,
  }

  componentDidMount() {
    const {
      workspace: { logoUrl },
    } = this.props

    if (this.isCurrentLogoCached) {
      this.setState({ dataUrl: logoUrl })
      return
    }

    if (logoUrl) {
      this.fetchAndCacheLogo(logoUrl)
    } else {
      localStorage.removeItem(this.cacheKey)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  componentDidUpdate({ workspace: { logoUrl: prevLogoUrl } }) {
    const {
      workspace: { logoUrl },
    } = this.props

    if (logoUrl !== prevLogoUrl && logoUrl) {
      this.fetchAndCacheLogo(logoUrl)
    } else if (!logoUrl) {
      localStorage.removeItem(this.cacheKey)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dataUrl: undefined })
    }
  }

  get cacheKey() {
    const {
      workspace: { uniqCode },
    } = this.props

    return `workspace-logo-${uniqCode}`
  }

  get isCurrentLogoCached() {
    const {
      workspace: { logoUrl },
      isCurrent,
    } = this.props
    const cache = localStorage.getItem(this.cacheKey)

    return !!(isCurrent && logoUrl && cache)
  }

  fetchAndCacheLogo = async url => {
    try {
      const { data } = await rootHttpClient.get(url, { responseType: 'blob', baseURL: '' })
      const reader = new FileReader()

      reader.onloadend = this.onFileLoaded
      reader.readAsDataURL(data)
    } catch {
      const dataUrl = localStorage.getItem(this.cacheKey)

      this.setState({ dataUrl })
    }
  }

  onFileLoaded = ({ target: reader }) => {
    const { result } = reader

    this.setState({ dataUrl: result })
    localStorage.setItem(this.cacheKey, result)
  }

  render = () => <WorkspaceLogoComponent {...this.props} {...this.state} />
}

export default WorkspaceLogo
