export const SET_WORKING_HOURS = 'https://help.expertbox.io/article/15-set-working-hours'
export const ADD_SERVICE = 'https://help.expertbox.io/article/137-add-services'
export const INVITE_TEAM_MEMBERS = 'https://help.expertbox.io/article/11-invite-team-members'
export const MANAGE_TEAM_MEMBERS = 'https://help.expertbox.io/article/149-manage-a-team-member'
export const SETUP_WIDGET = 'https://help.expertbox.io/article/22-set-up-your-widget'
export const SHARE_WIDGET = 'https://help.expertbox.io/article/170-share-widget-as-a-custom-page'
export const CONNECT_PAYMENT_METHOD = 'https://help.expertbox.io/collection/93-for-experts'
export const COLLECTION_FOR_EXPERTS = 'https://help.expertbox.io/collection/93-for-experts'
export const MY_CLIENTS = 'https://help.expertbox.io/category/126-clients'
export const GUIDE_TO_VIDEO_MEETINGS = 'https://help.expertbox.io/article/50-guide-to-expertbox-video-meetings'
export const SYNC_CALENDARS = 'https://help.expertbox.io/article/171-connect-google-calendar-to-expertbox'
export const MANAGE_APPOINTMENT = 'https://help.expertbox.io/article/55-cancel-or-reschedule-an-appointment'
export const FIND_MY_APPOINTMENTS = 'https://help.expertbox.io/article/35-find-my-appointments'
export const MANAGE_CLIENTS = 'https://help.expertbox.io/article/62-where-can-i-see-all-my-clients'
export const START_CHAT = 'https://help.expertbox.io/article/44-start-chatting-with-clients'
export const CREATE_CHAT = 'https://help.expertbox.io/article/177-create-a-chat-in-expertbox'
export const JOIN_CHAT = 'https://help.expertbox.io/article/178-join-and-leave-expertbox-chats'
export const HOW_CHAT_WORKS = 'https://help.expertbox.io/article/44-start-chatting-with-clients'
// TODO: add links after creating articles
export const SETUP_CLIENT_PORTAL = 'https://help.expertbox.io'
export const MANAGE_TASKS = 'https://help.expertbox.io'
export const MANAGE_VIDEO_CALLS_RECORDING = 'https://help.expertbox.io/article/53-record-meetings'
export const SETUP_WEBSITE = 'https://help.expertbox.io'
export const CUSTOMIZE_WEBSITE = 'https://help.expertbox.io'
export const CUSTOMIZE_INVOICE = 'https://help.expertbox.io'
export const ACCEPT_PAYMENTS = 'https://help.expertbox.io'
export const CUSTOM_PAYMENTS = 'https://help.expertbox.io'
export const REFUNDING_PAYMENTS = 'https://help.expertbox.io'
export const PROVIDING_SERVICES_OFFLINE = 'https://help.expertbox.io'
export const SEND_CUSTOM_PAYMENT_REQUESTS = 'https://help.expertbox.io'
export const ISSUE_INVOICES = 'https://help.expertbox.io'
export const NAMING_MANAGEMENT = 'https://help.expertbox.io'
export const SERVICE_AGREEMENTS = 'https://help.expertbox.io'
export const REVIEWS = 'https://help.expertbox.io'
export const SETUP_CALENDAR_INTEGRATIONS = 'https://help.expertbox.io'
export const STRIPE_POS_TERMINAL_AVAILABILITY = 'https://stripe.com/docs/terminal#availability'
export const CONNECT_STRIPE_CARD_READER = 'https://help.expertbox.io'
export const TWO_FACTOR_SETTINGS = 'https://help.expertbox.io'
export const SETUP_CLIENT_CONTACTS = 'https://help.expertbox.io'
export const SIGN_FORMS_AND_DOCUMENTS = 'https://help.expertbox.io'
export const CREATE_DOCUMENT = 'https://help.expertbox.io'
export const MANAGE_DOCUMENTS = 'https://help.expertbox.io'
export const SETUP_WAITLIST = 'https://help.expertbox.io'
export const CREATE_DOCUMENT_TEMPLATES = 'https://help.expertbox.io'
export const MANAGE_BUSINESS_FILES = 'https://help.expertbox.io'
export const CONTACT_US = 'https://expertbox.io/contact-us'
