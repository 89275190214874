import classNames from 'clsx'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'

import logo from 'assets/images/logo-mob.svg'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import WorkspaceLogo from 'views/shared/WorkspaceLogo'

const WorkspacePreview = ({ className, ...rest }) => {
  const workspace = useSelector(workspaceSelector)
  const logoName = workspace ? prop('name', workspace) : { id: 'shared.expertBox' }

  return (
    <div className={classNames('d-flex align-items-center gap-3', className)} {...rest}>
      {workspace ? (
        <WorkspaceLogo workspace={workspace} dataCy="login-workspace-image" isCurrent />
      ) : (
        <Image src={logo} alt="ExpertBox logo" width={32} height={32} data-cy="login-expertbox-image" />
      )}
      <div className="in-blue-gray-800 font-600 company-name-wrap company-name-break">
        <FormattedOrRawMessage message={logoName} />
      </div>
    </div>
  )
}

WorkspacePreview.defaultProps = {
  className: undefined,
}

WorkspacePreview.propTypes = {
  className: PropTypes.string,
}

export default WorkspacePreview
